.SwipeCardContainer {
  margin: 0;
  padding: 2rem 3rem 2rem 3rem;
  height: auto;
  width: 100%;
  background-color: white;
  color: black;
  display: flex;

  flex-direction: row;
  column-gap: 1rem;
  flex: 1;
  @media (max-width: 800px) {
    flex-direction: column;
    row-gap: 10px;
    border-top: 1px rgb(203, 203, 203) solid;
    border-bottom: 1px rgb(203, 203, 203) solid;
  }
  @media (min-width: 800px) {
    aspect-ratio: 3/1;
    border: 1px rgb(203, 203, 203) solid;
  }
  .SwipeCardImageContainer {
    height: 100%;
    width: 45%;
    background-color: rgb(203, 203, 203);
    border: 1px rgb(203, 203, 203) solid;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 800px) {
      width: 100%;
      aspect-ratio: 2/1;
    }
    .SwipeCardImage {
      width: 100%;
      height: auto;
      max-height: 100%;
      //max-width: 100%;
      object-fit: cover;
      @media (max-width: 800px) {
        aspect-ratio: 2/1;
      }
    }
  }
  .SwipeCardContentContainer {
    height: 100%;
    width: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    @media (max-width: 955px) {
      row-gap: 8px;
    }
  }
  .SwipeCardTitle {
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1;
    color: black;
    @media (max-width: 955px) {
      font-size: 1.2rem;
    }
  }
  .SwipeCardInfo {
    font-style: italic;
  }
  .SwipeCardContent {
    font-size: 1rem;
    color: gray;
    line-height: 1;
    @media (max-width: 955px) {
      font-size: 0.8rem;
    }
  }
  .SwipeCardUpdated {
    color: rgb(23, 56, 23);
  }
  .SwipeCardDate {
    font-weight: bold;
  }
  .SwipeCardLanguage {
    padding: 3px 5px 3px 5px;
    background-color: orange;
    color: white;
    font-weight: bold;
    border-radius: 3px;
  }
  .SwipeCardLanguageText {
    color: black;
    font-weight: bold;
  }
}
