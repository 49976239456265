.AuthCardContainer {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  justify-content: center;
  align-items: center;
  @media (max-width: 500px) {
    width: 100%;
    max-width: 100%;
  }
  .AuthCardCloseButtonRow {
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.popup-content {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  @media (max-width: 500px) {
    padding: 1rem;
  }
}
