.WideFooterBarContainer {
  width: 100%;
  max-width: 100%;
  height: fit-content; //important!
  min-height: 20rem;
  background-color: #333333;
  color: white;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 955px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .WideFooterBarContainerTopWrapper {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .WideFooterBarContainerTop {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
    row-gap: 8px;
    height: fit-content;
    .WideFooterBarContainerTopText {
      color: white;
    }
  }
  .WideFooterBarContainerBottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 955px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      row-gap: 1rem;
    }
    .WideFooterBarContainerBottomCopyRight {
      font-size: 12px;
    }
  }
}
