.TravelNoteCardContainer {
  width: 100%;
  max-width: 100%;
  height: auto;
  aspect-ratio: 2;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.5rem;
  .TravelNoteCardImageWrapper {
    width: 100%;
    max-width: 100%;
    height: auto;
    aspect-ratio: 2;
    background-color: gainsboro;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; /* Add a relative position to the wrapper */

    &::before {
      /* Create a pseudo-element to maintain aspect ratio */
      content: "";
      display: block;
      padding-top: 200%; /* Aspect ratio of 2/1 (divide height by width and multiply by 100) */
    }

    .TravelNoteImage {
      position: absolute; /* Position the image absolutely within the wrapper */
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      max-width: 100%;
      max-height: 100%;
    }
  }
  /* Add a media query to adjust the aspect ratio for devices with width less than 2x the height */
  @media (max-aspect-ratio: 2/1) {
    .TravelNoteCardImageWrapper::before {
      padding-top: 50%; /* Aspect ratio of 1/2 (divide width by height and multiply by 100) */
    }
  }
  .TravelNoteCardTitleWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    row-gap: 3px;
    align-items: center;
    .TravelNoteCardMainTitle {
      font-size: 1rem;
      font-weight: bold;
    }
    .TravelNoteCardSubTitleI {
      font-size: 12px;
      line-height: 12px;
    }
    .TravelNoteCardSubTitleII {
      font-size: 12px;
      line-height: 12px;
      color: gray;
    }
  }
  .TravelNoteCardInfoWrapper {
    width: 90%;
    max-width: 90%;
    height: fit-content;
    min-height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 12px;
    font-style: italic;
    @media (max-width: 955px) {
      min-height: auto;
    }
  }
  .TravelNoteCardBtn {
    width: 7rem;
    height: 2rem;
    justify-content: center;
    align-items: center;
    background-color: aliceblue;
    display: none;
  }
}
