.LeetcodeNoteContentPageContainer {
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .LeetcodeNoteContentPageBodyContainer {
    height: 100%;
    flex: 1;
    width: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    color: black;
    flex-grow: 1;

    @media (max-width: 955px) {
      padding: 1rem;
    }
    .LeetcodeNoteContentPageBodyInfoContainer {
      font-size: 12px;
      display: flex;
      flex-direction: row;
      column-gap: 3rem;
      color: gray;
      @media (max-width: 955px) {
        flex-direction: column;
      }
    }
    .LeetcodeNoteContentPageBodyTitleContainer {
      font-weight: bold;
      display: flex;
      font-size: 2rem;
    }
    .LeetcodeNoteContentPageBodyExternalLinkContainer {
      display: flex;
      font-size: 0.8rem;
      color: gray;
    }
    .LeetcodeNoteContentPageBodySubTitleContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .LeetcodeNoteContentPageBodySubTitles {
        display: flex;
        flex-direction: row;
        column-gap: 0.5rem;
      }
      .LeetcodeNoteContentPageBodySubButtons {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        column-gap: 2rem;
      }
      .LeetcodeNoteContentPageBodySubTitleWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        line-height: 12px;
        width: auto;
        height: 24px;
        padding-left: 1rem;
        padding-right: 1rem;
        border-radius: 14px;
        background-color: orange;
        color: white;
        font-weight: bold;
      }
    }
  }

  .LeetcodeNoteContentPageBodyContentContainer {
    height: 100%;
    overflow-y: auto;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    border-top: 1px solid gray;
    padding-top: 1rem;
    .react-quill {
      height: 100%;
    }
    .ql-editor {
      padding: 0 !important;
    }
  }

  .LeetcodeNoteContentPageFooterContainer {
    width: 100%;
    max-width: 100%;
  }
}
