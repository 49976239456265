.pdf-viewer {
  background-color: #f5f5f5; // 浅灰色背景
  position: absolute;
  transition: all 0.3s;
  border-radius: 15px;
  font-size: 12px;
  display: flex;
  flex-direction: row;

  .left-bar {
    width: 188px;
    background-color: #e0e0e0;
    display: flex;
    align-items: flex-start;
    padding-left: 20px;
    padding-top: 20px;
    border-top-left-radius: 15px; // 左上角圆角
    border-bottom-left-radius: 15px; // 右上角圆角

    button {
      border: none;
      background: none;
      cursor: pointer;
    }
  }

  &.expanded {
    top: 24px;
    bottom: 68px;
    left: 0;
    right: 0;
  }

  &.collapsed {
    width: 75%; // 宽度为屏幕宽度的75%
    height: calc(80vh - 92px); // 高度减去头部和底部的高度
    top: calc(24px + 12.5%); // 顶部位置
    left: 12.5%; // 左边位置
  }
}
