.NarrowFooterBarContainer {
  width: 100vw;
  max-width: 100%;
  padding-top: 20vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  &.whiteTheme {
    color: white;
  }
  &.blackTheme {
    color: black;
  }
  .NarrowFooterBarSvg {
    width: 100%;
    height: 70vw;
    background-image: url("../../../img/Background/Footer.svg");
    background-size: 120% auto;
    background-position: top center;
    background-repeat: no-repeat;
    &.blackTheme {
      background-image: url("../../../img/Background/Footer_black.svg");
    }
  }
  .NarrowFooterBarLogoContainer {
    margin-bottom: 20vw;
    width: 40vw;
    height: 100%;
    display: flex;
    justify-content: center;
    .NarrowFooterBarLogo {
      width: 100%;
      height: auto;
    }
  }
  .NarrowFooterBarMenuContainer {
    display: flex;
    flex-direction: column;
    row-gap: 5rem;
    text-align: center;
    margin-bottom: 10vw;
  }
  .NarrowFooterBarMenuLinkText {
    font-size: 16px;
    text-decoration: none;
  }
  .NarrowFooterBarToolContainer {
    display: flex;
    flex-direction: row;
    column-gap: 1vw;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
  }
  .NarrowFooterBarButtonWrapper {
    margin-top: 5rem;
    margin-bottom: 5rem;
    height: 2.5rem;
    width: 8rem;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffce00;
    font-size: 1rem;
    color: black;
  }
  .NarrowFooterBarAnalyzeChartContainer {
    margin-top: 5rem;
    width: 100%;
  }
}
