.desktopHeader {
  height: 24px;
  width: 100%;
  background-color: white;
  opacity: 0.85;
  position: fixed;
  top: 0;
  padding: 0 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  z-index: 1000;
  font-size: 12px;
  color: black;
}

@media (max-width: 600px) {
  .desktopHeader {
    display: none;
  }
}

.menu {
  display: flex;
  gap: 0px;
}

.dropdown {
  background-color: white;
  opacity: 0.85;
  border-radius: 6px;
  padding: 4px 14px;
  position: absolute;
  top: 25px;
  z-index: 1001;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: none;
}

.menuItem {
  font-size: 12px;
  color: black;
  padding: 4px 0;
}

.menu span {
  display: inline-block;
  height: 24px;
  line-height: 24px; /* 使文本垂直居中 */
  padding: 0 14px; /* 或者根据需要调整 */
  cursor: pointer;
}

.active {
  background-color: #f0f0f0; /* 激活状态的背景色 */
  border-radius: 5px;
}
