.NoteCardContainer {
  width: 100%;
  max-width: 100%;
  height: fit-content;
  color: black;
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgb(222, 222, 222);
  .NoteCardImage {
    width: 35%;
    height: auto;
    aspect-ratio: 2;
    //background-color: beige;
    .NoteCardImage {
      width: 100%;
      height: auto;
      max-height: 100%;
      //max-width: 100%;
      object-fit: cover;
      @media (max-width: 800px) {
        //aspect-ratio: 2/1;
      }
    }
  }
  .NoteCardInfoContainer {
    display: flex;
    flex-direction: column;
    .NoteCardTitle {
      font-size: 1rem;
      font-weight: bold;
      color: black;
      @media (max-width: 955px) {
        font-size: 0.8rem;
      }
    }
    .NoteCardInfo {
      font-size: 0.8rem;
      font-style: italic;
      color: gray;
      @media (max-width: 955px) {
        font-size: 0.6rem;
      }
    }
    .NoteCardNoteTaker {
      font-size: 0.8rem;
      color: gray;
      @media (max-width: 955px) {
        font-size: 0.6rem;
      }
    }
    .NoteCardBadge {
      width: auto;
      max-width: fit-content;
      padding: 3px 5px 3px 5px;
      border-radius: 5px;
      background-color: orchid;
      font-weight: bold;
      font-size: 0.8rem;
      color: white;
      @media (max-width: 955px) {
        font-size: 0.6rem;
      }
    }
  }
}
