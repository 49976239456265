.SubNoteMenuWelcome {
  width: 100%;
  max-width: 100%;
  height: auto;
  background-color: rgb(73, 73, 73);
  display: flex;
  flex-direction: row;
  padding: 2rem;
  min-height: 100%;
  @media (max-width: 955px) {
    height: fit-content;
    padding: 1rem;
    overflow: auto;
  }
  .SubNoteMenuWelcomeInfoSection {
    display: flex;
    flex-direction: column;
    color: white;
    flex: 1;
    .SubNoteMenuWelcomeInfoNav {
      font-size: 12px;
      font-weight: bold;
      color: rgb(251, 232, 140);
    }
    .SubNoteMenuWelcomeInfoTitle {
      font-size: 2rem;
      font-weight: bold;
      @media (max-width: 955px) {
        font-size: 1.5rem;
      }
    }
    .SubNoteMenuWelcomeInfoDetails {
      font-size: 1rem;
      font-style: italic;
    }
    .SubNoteMenuWelcomeInfoSubTitle {
      font-size: 14px;
    }
    .SubNoteMenuWelcomeInfoSubTitleRow {
      font-size: 14px;
      margin-top: 6rem;
      display: flex;
      flex-direction: row;
      column-gap: 1rem;
      @media (max-width: 955px) {
        flex-direction: column;
        margin-top: 2rem;
      }
    }
  }
}
.SubNoteMenuPageImportant {
  color: rgb(251, 232, 140);
}
.SubNoteMenuPageView {
  color: rgb(75, 147, 167);
}
.SubNoteMenuPageDownload {
  color: rgb(12, 113, 141);
  display: none;
}
.SubNoteMenuCourseContent {
  height: 100%;
  width: 100%;
  max-width: 100%;
  padding: 2rem;
  color: black;
  display: flex;
  flex-direction: column;
  @media (max-width: 955px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .SubNoteMenuCourseContentTitle {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    @media (max-width: 955px) {
      font-size: 1.2rem;
    }
  }
  .SubNoteMenuCourseContentSubTitle {
    font-size: 1rem;
    color: gray;
    @media (max-width: 955px) {
      font-size: 0.8rem;
    }
  }
  .SubNoteMenuCourseContentMenuList {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    width: 60%;
    @media (max-width: 955px) {
      width: 100%;
    }
    .SubNoteMenuCourseContentMenuRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-top: 1rem;
      padding-bottom: 1rem;
      justify-content: space-between;
      //border-bottom: 1px solid rgb(192, 192, 192);
      .SubNoteMenuCourseContentMenuRowTitle {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 1rem;
      }
      .SubNoteMenuCourseContentMenuRowText {
        @media (max-width: 955px) {
          display: none;
        }
      }
    }
  }
}
