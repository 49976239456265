// src/pages/PrivacyPolicyPage/PrivacyPolicyPage.scss
.privacyPolicyPage {
  padding: 20px;
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 18px;
  }
  .FirstRow {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    margin-bottom: 2rem;
  }

  /* Adding Media Query for smaller screens */
  @media (max-width: 768px) {
    /* Adjust the max-width value based on your design needs */
    .FirstRow {
      flex-direction: column;
      row-gap: 1rem;
    }
  }
}
