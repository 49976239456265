.PollPageContainer {
  width: 100vw;
  max-width: 100%;
  height: fit-content;
  min-height: 100vh;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  background-color: black;
}
.PersonaPollContainer {
  width: 390px !important;
  height: 844px !important;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: white;
  background-color: black;
  transform-origin: top left;
  .PollResultHeaderContainer {
    width: 100%;
    aspect-ratio: 1;
  }
  .returnButtonContainer {
    margin-top: -15%;
    width: 100%;
    height: 5%;
    padding-right: 20px;
    display: flex;
    justify-content: flex-end;
    .returnButton {
      color: red;
    }
  }
  .QuestionContainer {
    width: 100%;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    height: auto;
  }

  .ButtonContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    row-gap: 3%;
    padding-top: 8%;
    align-items: center;
    justify-content: center;
    .ButtonWrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      column-gap: 5rem;
      align-items: center;
      .button-style {
        background-color: transparent;
        border: none;
        cursor: pointer;
        padding: 0;
        display: flex;
      }
    }
  }
  .LogInWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    align-items: center;
    justify-content: center;
    .LogInMessageContainer {
      width: 80%;
      height: auto;
      text-align: center;
      color: red;
      font-weight: 600;
      .LogInText {
        color: white;
        background-color: red;
        cursor: pointer;
        font-weight: bolder;
      }
    }
    .LogInButton {
      width: 60%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .voteRecordWrapper {
    margin-top: 10%;
    color: red;
  }
}

.svg-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.svg-button .svg1 {
  display: block;
}

.svg-button .svg2 {
  display: none;
}

.svg-button:active .svg1 {
  display: none;
}

.svg-button:active .svg2 {
  display: block;
}

.svg-button:active .logInSvg {
  opacity: 0.6;
}
