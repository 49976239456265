.MessagesMainPageContainer {
  width: 100%;
  max-width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: black;
  .MessagesMainPageWelcomeContainer {
    width: 100%;
    height: 10rem;
    background-image: url("../../../img/Background/popteamepic.jpg");
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
  }
  .MessagesMainPagePostArea {
    width: 60%;
    padding: 2rem;
    padding-bottom: 0;
    height: fit-content;
    @media (max-width: 955px) {
      width: 100%;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
    .MessagesMainPagePost_Wrapper {
      height: 4rem;
      //border: 1px solid rgb(188, 188, 188);
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      padding: 0.5rem;
      column-gap: 1rem;
      flex: 1;
      @media (max-width: 955px) {
        height: 3rem;
      }
      .MessagesMainPagePost_AuthorImage {
        height: 100%;
        aspect-ratio: 1;
        border-radius: 50%;
        background-image: url("../../../img/Background/chimitan.png");
        background-size: auto 100%;
        background-position: center;
        background-repeat: no-repeat;
      }
      .MessagesMainPagePost_Input {
        flex-grow: 1;
        height: 100%;
        border-radius: 5px;
        border: 1px solid #dedede;
        background-color: #f4f4f4;
        .MessagesMainPagePost_InputBox {
          height: 100%;
          flex-grow: 1;
        }
      }
      .MessagesMainPagePost_Button {
        border: none;
        border-radius: 4px;
        font-size: 12px;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }
  }
  .MessagesMainPageMainTitle {
    padding-top: 2rem;
    font-size: 1.5rem;
    font-weight: bold;
    padding-left: 2rem;
    @media (max-width: 955px) {
      font-size: 1.2rem;
      padding-left: 1rem;
    }
  }
  .MessagesMainPageContentsContainer {
    width: 60%;
    padding: 0rem 2rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    @media (max-width: 955px) {
      width: 100%;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  .MessagesMainPageLoadingContainer {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f4f4f4;
    .MessagesMainPageLoadingImageContainer {
      width: 500px;
      height: 200px;
      background-image: url("../../../img/Background/hakasei.gif");
      background-size: auto 100%;
      background-position: center;
      background-repeat: no-repeat;
      @media (max-width: 500px) {
        width: 100%;
        max-width: 100%;
      }
    }
    .MessagesMainPageLoadingImageMioContainer {
      width: 540px;
      height: 300px;
      background-image: url("../../../img/Background/mio.gif");
      background-size: auto 100%;
      background-position: center;
      background-repeat: no-repeat;
      @media (max-width: 540px) {
        width: 100%;
        max-width: 100%;
      }
    }
  }
  .MessagesMainPageContentWrapper {
    display: flex;
    flex-direction: column;
    font-size: 0.8rem;
    border-top: 1px solid rgb(213, 213, 213);
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    .MessagesMainPageFirstLineWrapper {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      color: gray;
      .MessagesMainPageFirstLineWrapper_Left {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        .MessagesMainPageFirstLineWrapper_DeleteButton {
          cursor: pointer;
          color: green;
        }
      }
      .MessagesMainPageAuthorImage {
        height: 24px;
        width: 24px;
        background-color: rgb(197, 197, 197);
        margin-right: 0.5rem;
        background-image: url("../../../img/Background/chimitan.png");
        background-size: auto 100%;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
    .MessagesMainPageMessageContentWrapper {
      padding-top: 0.5rem;
      padding-left: 1.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 0.5rem;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: rgb(95, 52, 5);
    }

    .MessagesMainPagePreviewContentWrapper {
      max-height: 8rem;
      min-height: 2rem;
      overflow: hidden;
      padding-top: 0.5rem;
      position: relative;
      &:before {
        //z-index: 9;
        //content: "";
        //position: absolute;
        //bottom: 0;
        //left: 0;
        //right: 0;
        //height: 100%; /* 调整遮罩层的高度 */
        //pointer-events: none;
        //background: linear-gradient(rgba(255, 255, 255, 0), rgb(255, 255, 255));
      }
      .react-quill {
        z-index: 0;
      }
      .ql-editor {
        padding: 0 !important;
      }
    }
  }

  .MessagesMainPageFooterContainer {
    width: 100%;
    max-width: 100%;
  }
}
.MessagesMainPageAuthorName {
  color: rgb(95, 52, 5);
  font-weight: bold;
}
