.NoteKrenzPageContainer {
  width: 100vw;
  max-width: 100%;
  height: 100%; /* Use vh (viewport height) instead of % for full window height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  color: black;
  //background-color: black;

  .react-pdf__Document {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .react-pdf__Page {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }

  .NoteKrenzPageHeaderBarContainer {
    width: 100vw;
    max-width: 100%;
    height: 100%;
  }
  .NoteKrenzPageFooterBarContainer {
    width: 100vw;
    max-width: 100%;
  }
  .NoteKrenzPageSubHeaderContainer {
    width: 100%;
    height: 100%;
    padding: 1rem 2rem 1rem 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: black;
    border-bottom: rgb(213, 213, 213) 1px solid;
    @media (max-width: 955px) {
      width: 100vw;
      max-width: 100%;
      padding: 0.5rem 1rem 0.5rem 1rem;
    }

    .NoteKrenzPageSubHeaderPageContainer {
      height: 100%;
      width: auto;
      display: flex;
      flex-direction: row;
      column-gap: 2rem;
    }
  }

  .NoteOnePagePDFContainer {
    width: 100vw;
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }
  .NoteTwoPagePDFContainer {
    width: 100vw;
    max-width: 100%;
    height: auto;
    aspect-ratio: 3/2;
    display: flex;
    flex-direction: row;
    .NoteTwoPagePDFWrapper {
      width: 50vw;
      max-width: 50%;
      height: auto;
      aspect-ratio: 3/4;
      background-color: white;
    }
  }
}
.react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
}
.NoteKrenzPageSignInContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .NoteKrenzPageSignInHeader {
    width: 100%;
    text-align: right;
  }
  .NoteKrenzPageSignInBody {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    justify-content: center;
    align-items: center;
  }
  .NoteKrenzPageSignInImg {
    width: 10rem;
    height: auto;
    max-width: 10rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    .PopukuPng {
      width: 100%;
      height: auto;
      max-width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .NoteKrenzPageSignInText {
    display: flex;
    text-align: center;
    color: #ce5b8e;
    border: 1px solid #c05185;
    background-color: white;
    border-radius: 10px;
    padding: 1rem;
  }
  .NoteKrenzPageSignInButton {
    width: 10rem;
    height: 2rem;
    background-color: #eea8c7;
    color: white;
    display: flex;
    border: 1px solid #dd89b0;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
