.AboutLeftPageContainer {
  width: 895px;
  height: 1100px;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  .AboutLeftPageMainTitleContainer {
    width: 895px;
    height: 120px;
    font-size: 5rem;
    line-height: 5rem;
    font-weight: bolder;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .AboutLeftPageDateContainer {
    width: 895px;
    height: 30px;
    border-bottom: 1px solid black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
  .AboutLeftPageDataNewsContainer {
    width: 895px;
    height: 500px;
    flex-grow: 1;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    .AboutLeftPageDataContainer {
      height: 100%;
      width: 585px;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      row-gap: 5px;
      position: relative;
      .AboutLeftPageDataImgContainer {
        height: 275px;
        background-color: #a29d8a;
        border: 1px solid black;
        background-image: url("../../../img/Background/yusuke.gif");
        background-size: 100% auto;
        background-position: top center;
        background-repeat: no-repeat;
      }

      .AboutLeftPageDataTitleContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: bolder;
        font-size: 2rem;
      }
      .AboutLeftPageDataText {
        width: 585px;
        flex: 1;
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        justify-content: flex-start;
        flex-grow: 1;
        .AboutLeftPageDataContents {
          width: 280px;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: bold;
        }
        .AboutLeftPageDataList {
          flex: 1;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .AboutLeftPageDataListItems {
            display: flex;
            flex-direction: column;
            row-gap: 0.5rem;
          }
          .AboutLeftPageDataRow {
            width: 100%;
            border-bottom: 1px solid black;
            display: flex;
            justify-content: space-between;
          }
          .AboutLeftPagePreview {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            .openPDFButton {
              background-color: #a29d8a;
              color: black;
              cursor: pointer;
              padding: 3px 8px 3px 8px;
            }
          }
        }
      }
    }

    .AboutLeftPageNewsContainer {
      height: 100%;
      width: 300px;
      border-top: 1px solid black;
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      flex-grow: 1 !important;

      .AboutLeftPageNewsTitle {
        border-top: 30px solid black;
        padding-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bolder;
        font-size: 2rem;
        line-height: 2rem;
      }

      .AboutLeftPageNewsImage {
        height: 230px;
        background-color: black;
        background-image: url("../../../img/Background/lynel.gif");
        background-size: auto 100%;
        background-position: top center;
        background-repeat: no-repeat;
      }

      .AboutLeftPageNewsText {
        width: auto;
        border: 1px solid black;
        flex: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 0.8rem;
        font-weight: bold;
        padding: 1rem;
        overflow: hidden;
      }
      .AboutLeftPageNewsChineseText {
        font-size: 1rem;
      }
    }
  }

  .AboutLeftPageSkillsContainer {
    width: 895px;
    height: 420px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    justify-content: space-between;
    flex-grow: 1;
    .AboutLeftPageSkillsChartContainer {
      height: 100%;
      aspect-ratio: 1;
      width: auto;
      border: 1px solid black;
      padding: 5px;
    }
    .AboutLeftPageSkillsTextContainer {
      height: 100%;
      width: auto;
      flex: 1;
      border-top: 1px solid black;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }
    .AboutLeftPageSkillsTitleContainer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-weight: bolder;
      font-size: 2rem;
    }
    .AboutLeftPageSkillsContentContainer {
      width: 100%;
      height: auto;
      font-weight: bold;
      line-height: 20px;
      column-count: 2; /* Adjust the number of columns as needed */
      column-gap: 10px;
    }
    .AboutLeftPageSkillsImgContainer {
      width: 100%;
      flex: 1;
      background-color: #a29d8a;
      background-image: url("../../../img/Background/albus-dumbledore.gif");
      background-size: 100% auto;
      background-position: top center;
      background-repeat: no-repeat;
    }
  }
}
