.AuthContainer {
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  row-gap: 10vh;
  background-color: white;
  .AuthBody {
    width: 100%;
    height: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .Header {
    width: 100%;
  }
  .Footer {
    width: 100%;
    max-width: 100%;
  }
}
