.PersonaLoadingPageContainer {
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  .gifContainer {
    width: 120px;
    max-width: 120px !important;
    height: auto;
  }
}
