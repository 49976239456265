.MapCardContainer {
  width: 100%;
  max-width: 100%;
  height: fit-content;
  color: black;
  margin: 2rem 0rem 2rem 0rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  position: relative;
  @media (max-width: 955px) {
    margin: 1rem 0rem 1rem 0rem;
    row-gap: 4px;
  }
  .MapWrapper {
    width: 100%;
    max-width: 100%;
    height: auto;
    aspect-ratio: 3;
    background-color: beige;
    border: 1px solid rgb(213, 213, 213);
  }
  .MapTipWrapper {
    font-size: 1rem;
    font-style: italic;
    color: gray;
    @media (max-width: 955px) {
      font-size: 12px;
    }
  }
}
