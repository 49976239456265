/* In your component's CSS file or as inline styles */
.custom-table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  font-size: 12px;
  @media (max-width: 955px) {
    //font-size: 0.8rem;
    //line-height: 1rem;
  }
}

.custom-table th {
  background-color: #e2e8f0; /* Set the table header background color to black */
  color: black; /* Set the table header text color to white */
  align-items: center;
  font-weight: bold;
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: left;
  cursor: pointer;
}

.custom-table td {
  background-color: white;
  color: black;
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: left;
  border-bottom: 1px rgb(234, 234, 234) solid;
}

.title-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.LeetcodeNotesTableContainer {
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  overflow-x: hidden;
  max-width: 100%;
  border-radius: 0.5rem;
  border: 1px solid #e2e8f0;
}

.LeetcodeNotesTablePaginationContainer {
  background-color: #e2e8f0;
  color: #4b4c4e;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 12px;
  .LeetcodeNotesTablePaginationContainer_Buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 50%;
    @media (max-width: 955px) {
      width: 100%;
    }
  }
  .LeetcodeNotesTablePaginationContainer_Arrows {
    color: #a5adb7;
  }
  .LeetcodeNotesTablePaginationContainer_Inputs {
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    @media (max-width: 955px) {
      display: none;
    }
  }
}

.LeetcodeNotesTablePaginationContainer button,
.LeetcodeNotesTablePaginationContainer select,
.LeetcodeNotesTablePaginationContainer input {
  background-color: transparent;
  color: #4b4c4e;
  border: 1px solid #4b4c4e;
  padding: 4px 10px;
  margin: 0 5px;
  border-radius: 4px;
}

.LeetcodeNotesTablePaginationContainer button:disabled,
.LeetcodeNotesTablePaginationContainer select,
.LeetcodeNotesTablePaginationContainer input {
  opacity: 0.5;
  cursor: not-allowed;
}

.LeetcodeNotesTablePaginationContainer span {
  display: flex;
  align-items: center;
}

.LeetcodeNotesTablePaginationContainer select {
  cursor: pointer;
}
