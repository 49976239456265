.App {
  height: 100vh !important;
  width: 100vw !important;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;

  @media (max-width: 415px) {
    align-items: flex-start;
    justify-content: center;
    background-color: black;
    height: auto !important;
    min-height: 100% !important;
  }
}
.personaPollContainer {
  background-color: black;
}
