.FooterBarNarrow {
  width: 100%;
  max-width: 100%;
  @media (min-width: 955px) {
    display: none;
  }
}
.FooterBarWide {
  width: 100%;
  max-width: 100%;
  @media (max-width: 955px) {
    display: none;
  }
}
