.HeaderBarContainer {
  width: 100%;
  max-width: 100%;
  height: 4rem;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  &.whiteTheme {
    border-bottom: 1px solid white;
    color: white;
  }
  &.blackTheme {
    border-bottom: 1px solid black;
    color: black;
  }
  @media (max-width: 955px) {
    justify-content: center;
  }

  .HeaderBarMenuContainer {
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 3rem;
    @media (max-width: 955px) {
      display: none;
    }
    .HeaderBarMenuItemText {
      text-decoration: none;
      font-size: 16px;
    }
  }
  .HeaderBarLogoContainer {
    height: 100%;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    .HeaderBarLogo {
      width: 100%;
      height: auto;
    }
    @media (max-width: 955px) {
      width: 40%;
    }
  }
  .HeaderBarToolContainer {
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    column-gap: 2rem;
    @media (max-width: 955px) {
      display: none;
    }
    .HeaderBarToolLanguageSwitch {
      display: flex;
      flex-direction: row;
      column-gap: 4px;
      align-items: center;
    }
    .HeaderBarToolAuthButton {
      height: 2rem;
      width: 6rem;
      //border-radius: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &.whiteTheme {
        background-color: white;
        color: black;
      }
      &.blackTheme {
        background-color: black;
        color: white;
      }
      .HeaderBarToolAuthButtonText {
        cursor: pointer;
      }
    }
  }
}
.HeaderBarMenuDropdown {
  background-color: transparent;
  border: none;
  &:hover {
    background-color: transparent;
  }
}
