.PersonaPageContainer {
  width: 100vw;
  max-width: 100%;
  height: fit-content;
  min-height: 100vh;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  background-color: black;
}
.PersonaMainPageContainer {
  width: 390px !important;
  height: 844px !important;
  transform-origin: top left;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgb(0, 0, 0);
  color: white;
  padding-top: 10%;
  background-image: url("../../../img/PersonaPage/pollPageBackground.svg");
  background-size: 100% auto;
  background-position: center top;
  background-repeat: no-repeat;
  .BodyContainer {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    row-gap: 4rem;
  }
  .PageHeader {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .PollPageTitleContainer {
    width: 70%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .PollPageQuestionContainer {
    width: 90%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .PollPageQuestionContainer img {
    width: 100%;
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }
  .PollPageResultContainer {
    width: 80%;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid red;
    margin-bottom: 10%;
    justify-content: space-between;
    .AnswerIconsWrapper {
      display: flex;
      flex-direction: row;
      align-self: flex-end;
      justify-content: flex-end;
      align-items: flex-end;
      .AnswerAIcon {
        height: 43.18px;
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .AnswerYesIcon {
        margin-bottom: 5px;
        height: 30px;
        width: auto;
        display: flex;
      }
    }

    .AnswerResultWrapper {
      display: flex;
      flex-direction: row;
      align-self: flex-end;
      column-gap: 0.3rem;
      .AnswerText {
        font-size: 80px;
        font-weight: 900;
        color: orange;
        line-height: 65px;
        align-self: flex-end;
        letter-spacing: -0.07em;
      }
      .AnswerPercentageIcon {
        height: 63.29px;
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: flex-end;
      }
    }
  }

  .PollWrapper {
    display: flex;
    flex-direction: row;
    //align-items: center;
    //justify-content: space-between;
    .button-style {
      background-color: transparent;
      border: none;
      //cursor: pointer;
      padding: 0;
      z-index: 2;

      display: flex;
    }
    .yes-button {
      margin-right: -3.5rem;
      align-items: flex-end;
      margin-bottom: -1rem;
    }
    .no-button {
      margin-left: -1.5rem;
      align-items: flex-end;
    }
    .yes-button:hover {
      //transform: scale(1.1);
    }
    .no-button:hover {
      //transform: scale(1.1);
    }
    .PollBarContainer {
      position: relative;
      display: inline-block;
    }
  }

  .thanksNote {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    width: 300px;
    text-align: center;
    color: black;
    text-shadow: 1px 1px 0 red, -1px -1px 0 red, 1px -1px 0 red, -1px 1px 0 red;
    font-weight: bold;
  }

  .moreButtonsWrapper {
    width: 85%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2rem;
    padding-right: 10px;
  }

  .logoContainer {
    border-bottom: 1px solid black;
    height: 80px;
    width: 100%;
    //background-color: blue;
    display: flex;
    justify-content: center;
    align-items: center;
    .logo {
      height: 100%;
      width: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .PersonaMainPageLanguageWrapper {
    margin-top: 1rem;
  }
  .PersonaMainPageLanguageButton {
    cursor: pointer;
    &.selected {
      text-decoration: underline;
    }
  }
}
.svg-button {
  border: none;
  font-size: 16px;
  color: white;
  background-color: transparent;
  cursor: pointer;
}

.svg-button svg {
  fill: black;
  transition: transform 0.2s ease-in-out;
}

.svg-button:hover svg {
  transform: scale(1.1);
}
