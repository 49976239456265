.TravelNoteCardsContainer {
  width: 100%;
  max-width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  margin-bottom: 2rem;
  @media (max-width: 755px) {
    row-gap: 1rem;
  }
  .TravelNoteCardsRowWrapper {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    color: black;
    @media (max-width: 755px) {
      flex-direction: column;
      row-gap: 1rem;
    }
  }
  .TravelNoteCardsItemWrapper {
    height: auto; //fit-content;
    aspect-ratio: 2 !important;
    width: 33%;
    //max-width: 100%;
    @media (max-width: 755px) {
      width: 100%;
    }
  }
  .emptySpace {
    visibility: hidden;
    @media (max-width: 755px) {
      display: none;
    }
  }
}
