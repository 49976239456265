// HomePage.scss

$background-image-path: "../../../img/macOS/field.jpg"; // Update the path as necessary

.outerStyle {
  width: 100%;
  height: 100vh;
  padding-bottom: 68px;
  position: relative;
  box-sizing: border-box;
}

.backgroundStyle {
  background-image: url($background-image-path);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
