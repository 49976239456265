.AboutPageContainer {
  width: 100vw;
  max-width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: #dddacf;
  .AboutPageBodyContainer {
    width: 100%;
    max-width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 0 !important;
    @media (max-width: 955px) {
      flex-direction: column;
    }
    .AboutPageLeftContainer {
      width: 50%;
      border-right: 1px solid black;
      height: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 955px) {
        width: 100%;
        max-width: 100%;
        border-right: none;
        margin-bottom: 4rem;
      }
    }
    .AboutPageRightContainer {
      width: 50%;
      height: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 955px) {
        width: 100%;
        max-width: 100%;
      }
    }
  }
  .AboutPageFooterContainer {
    width: 100%;
    max-width: 100%;
  }
}
