.NoteMainPage {
  width: 100vw;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  //flex-grow: 1;
  overflow-y: auto;
  color: white;
  background-color: white;

  .NoteMainPageHeader {
    width: 100%;
    height: auto;
    background-color: white;
  }

  .NoteMainPageWelcome {
    width: 100%;
    height: auto;
    aspect-ratio: 5;
    background-image: url("../../../img/Background/welcome.jpg");
    background-size: 100% auto;
    background-position: center 70%;
    background-repeat: no-repeat;
    @media (max-width: 955px) {
      aspect-ratio: 3;
      background-image: url("../../../img/Background/welcome.gif");
      background-position: center;
    }
  }

  .NoteMainPageMainBodySwiper {
    height: auto;
    width: 90vw;
    max-width: 1200px;
    @media (max-width: 955px) {
      width: 100%;
    }
  }
  .NoteMainPageMainBody {
    height: auto;
    width: 90vw;
    max-width: 1200px;
    @media (max-width: 955px) {
      width: 100%;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .NoteMainPageTitle {
    margin-top: 2rem;
    color: black;
    //margin-left: 5vw;
    font-size: 2rem;
    font-weight: bold;
    @media (max-width: 955px) {
      display: none;
    }
  }
  .NoteMainPageSubTitle {
    margin-top: 2rem;
    color: black;
    //margin-left: 5vw;
    font-size: 2rem;
    line-height: 2rem;
    font-weight: bold;
    @media (max-width: 955px) {
      //margin-left: 1rem;
      margin-top: 1rem;
      font-size: 1.2rem;
      line-height: 1.2rem;
    }
  }

  .swiperContainer {
    margin-top: 1rem;
    margin-bottom: 2rem;
    height: auto;
    width: 100%;

    @media (max-width: 955px) {
      width: 100%;
      max-width: 100%;
      margin-top: 0;
    }
  }
  .blue-slide,
  .yellow-slide,
  .green-slide {
    height: 100%;
    width: 100%;
    max-width: 100%;
    font-size: 36px;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .blue-slide {
    background-color: blue;
  }

  .NoteMainPageNoteCardContainer {
    height: auto;
    width: 100%;
    //padding-left: 5%;
    //padding-right: 5%;
    @media (max-width: 955px) {
      //padding-left: 5%;
    }
  }

  .NoteMainFooterContainer {
    max-width: 100%;
    width: 100%;
  }

  .NoteMainPageLeetcodeNotes {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    align-items: flex-end;
  }
}
