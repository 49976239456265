body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: "CustomFont", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Sadekids-Regular";
  font-style: normal;
  font-weight: 400;
  src: local("Sadekids-Regular"),
    url("https://chensitesbucket.s3.amazonaws.com/fonts/handwritten/Sadekids-Regular.ttf")
      format("truetype");
}

@font-face {
  font-family: "NanumPenScript-Regular";
  font-style: normal;
  font-weight: 400;
  src: local("NanumPenScript-Regular"),
    url("https://chensitesbucket.s3.amazonaws.com/fonts/handwritten/NanumPenScript-Regular.ttf")
      format("truetype");
}

@font-face {
  font-family: "EnglishTowne";
  font-style: normal;
  font-weight: 400;
  src: local("EnglishTowne"),
    url("https://chensitesbucket.s3.amazonaws.com/fonts/newspaper/EnglishTowne.ttf")
      format("truetype");
}

@font-face {
  font-family: "Sanji-Pomo";
  font-style: normal;
  font-weight: 400;
  src: local("Sanji-Pomo"),
    url("https://chensitesbucket.s3.amazonaws.com/fonts/newspaper/%E4%B8%89%E6%9E%81%E6%B3%BC%E5%A2%A8%E4%BD%93.ttf")
      format("truetype");
}

@font-face {
  font-family: "Nirtiery-Quentin";
  font-style: normal;
  font-weight: 400;
  src: local("Nirtiery-Quentin"),
    url("https://chensitesbucket.s3.amazonaws.com/fonts/newspaper/NirtieryQuentin-jEMPv.otf")
      format("opentype");
}

@font-face {
  font-family: "BaoXiaoZhi";
  font-style: normal;
  font-weight: 400;
  src: local("BaoXiaoZhi"),
    url("https://chensitesbucket.s3.amazonaws.com/fonts/newspaper/BiaoXiaoZhi.ttf")
      format("truetype");
}

@font-face {
  font-family: "Domine-Regular";
  font-style: normal;
  font-weight: 400;
  src: local("Domine-Regular"),
    url("https://chensitesbucket.s3.amazonaws.com/fonts/newspaper/Domine-Regular.ttf")
      format("truetype");
}

@font-face {
  font-family: "FangZheng-KaiTi";
  font-style: normal;
  font-weight: 400;
  src: local("FangZheng-KaiTi"),
    url("https://chensitesbucket.s3.amazonaws.com/fonts/newspaper/FangZheng-KaiTi.ttf")
      format("truetype");
}

a {
  text-decoration: none;
}
