.AboutRightPageContainer {
  width: 895px;
  height: 1100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  flex-grow: 1;
  font-family: "Times New Roman", Times, serif;
  .AboutRightPageLeftContainer {
    width: 300px !important;
    min-width: 300px !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    .AboutRightPageLeftGoodNewsContainer {
      width: 100%;
      height: 100px;
      border: 1px solid black;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2.5rem;
      font-weight: bold;
    }
    .AboutRightPageLeftGoodNewsSubTitle {
      width: 100%;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      text-align: center;
      border-top: 3px solid #a29d8a;
      padding-top: 0.5rem;
      border-bottom: 3px solid #a29d8a;
      padding-bottom: 0.5rem;
    }
    .AboutRightPageLeftGoodNewsContent {
      text-align: center;
      width: 100%;
      display: flex;
      //height: 400px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .AboutRightPageLeftGoodNewsImg {
      width: 100%;
      height: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      border: 1px solid black;
      background-image: url("../../../img/Background/nichijou.gif");
      background-size: auto 100%;
      background-position: center;
      background-repeat: no-repeat;
    }
    .AboutRightPageLeftContent {
      width: 100%;
      height: auto;
      flex: 1;
      background-color: #a29d8a;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 3rem;
      padding: 1rem;
      text-align: center;
    }
  }
  .AboutRightPageRightContainer {
    height: 100%;
    width: auto;
    max-width: 575px;
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    flex-grow: 1;
    .AboutRightPageRightTopContainer {
      min-height: 370px;
      height: auto;
      width: 100%;
      display: flex;
      flex-direction: row;
      column-gap: 10px;
      .AboutRightPageRightTopText {
        width: auto;
        flex: 1;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        padding-bottom: 10px;
        .AboutRightPageRightTopTitle {
          font-size: 2rem;
          font-weight: bold;
        }
        .AboutRightPageRightTopContent {
          font-weight: bold;
          line-height: 1.3;
        }
        .AboutRightPageRightTopContentEnglish {
          font-size: 1rem;
          line-height: 18px;
        }
      }
      .AboutRightPageRightTopImage {
        width: 250px;
        background-color: #a29d8a;
        border: 1px solid black;
        background-image: url("../../../img/Background/splatoon.gif");
        background-size: auto 100%;
        background-position: top center;
        background-repeat: no-repeat;
      }
    }
    .AboutRightPageRightMiddleContainer {
      height: auto;
      flex: 1;
      width: 100%;
      background-color: #a29d8a;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 30px;
      color: white;
    }
    .AboutRightPageRightBottomContainer {
      height: 560px;
      width: 100%;

      display: flex;
      flex-direction: column;
      row-gap: 10px;
      flex-grow: 1;
      .AboutRightPageRightBottomText {
        width: 575px;
        max-width: 100%;
        flex: 1;
        font-weight: bold;
        column-count: 3; /* Adjust the number of columns as needed */
        column-gap: 5px;
      }
      .AboutRightPageRightBottomImage {
        height: 220px;
        width: 100%;
        background-color: black;
        border: 1px solid black;
        background-image: url("../../../img/Background/potter.gif");
        background-size: 100% auto;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}
