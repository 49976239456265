.AnalyzeChartContainer {
  width: 100%;
  aspect-ratio: 3/1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &.lightContainer {
    //border-bottom: 1px solid white;
    border-top: 1px solid white;
    border-left: 1px solid white;
    color: white;
  }
  &.darkContainer {
    border-bottom: 1px solid black;
    border-top: 1px solid black;
    border-left: 1px solid black;
    color: black;
  }
  .AnalyzeChartSubContainer {
    height: 100%;
    width: auto;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 0.5rem;
    &.lightSubContainer {
      border-right: 1px solid white;
    }
    &.darkSubContainer {
      border-right: 1px solid black;
    }
    .AnalyzeChartSubValue {
      font-weight: bold;
    }
  }
}
