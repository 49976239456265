.EditPageContainer {
  width: 100vw;
  max-width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #dae0e6;
  //justify-content: center;
  .EditPageBodyContainer {
    height: 100%;
    flex: 1;
    width: 100%;
    max-width: 100vw;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    color: black;
    flex-grow: 1;
  }
  .EditPageLogoContainer {
    width: 100%;
    border-bottom: 2px white solid;
    font-size: 1.5rem;
    font-weight: bold;
    color: rgb(62, 62, 62);
    padding-bottom: 0.2rem;
    margin-bottom: 0.5rem;
  }
  .EditPageTitleContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0.2rem;
  }
  .EditPageInputTitleContainer {
    width: 100%;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 0.2rem;
  }
  .EditPageInputContainer {
    background-color: white;
    border: none;
    border-radius: 5px;
    height: 40px;
  }
  .MuiInputBase-root {
    height: 40px !important;
  }

  .EditPageTypeRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    .EditPageColumn {
      width: 30%;
      min-width: 125px;
    }
    @media (max-width: 955px) {
      flex-direction: column;
      row-gap: 1rem;
      .EditPageColumn {
        width: 100%;
      }
    }
  }
  .EditPageSubmitButtonContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .EditPageSubmitButton {
      height: 40px;
      width: 120px;
      border-radius: 5px;
      background-color: #4c5f72;
      color: white;
      font-size: 1rem;
      border: none;
    }
  }
  .EditPageFooterContainer {
    width: 100%;
    max-width: 100%;
  }
}
.ql-editor {
  //min-height: 300px;
  //max-height: 300px;
}
.CustomizeReactQuill {
  background-color: white;
  border-radius: 5px;
}
